import React, { useEffect } from "react";
import "./index.scss";
import ContactFormForm from "../../modules/ContactFormSection";
import { Text, Media } from "@mbank-design/design-system/components";
import { FontColor } from "@mbank-design/design-system/enums";
import { FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";

import backgroundImageWeb from "./img/afi_web.jpg";
import backgroundImageMobile from "./img/afi_mobile.jpg";
import backgroundImageWebWebP from "./img/afi_web.webp";
import backgroundImageMobileWebP from "./img/afi_mobile.webp";
import { clearCampaignData, setCampaignData } from "../../common/campaign-data-setter";
import { Helmet } from "react-helmet";

const LandingPageAfi = (props) => {
  const isWebpSupported = webpSupported();

  
  const downloadSiteRules = () => {
    props.downloadSiteRules().then((res) => {
      props.clickAnchorWithRegulations(res);
    });
  };

  useEffect(()=>{
    clearCampaignData();
    setCampaignData()
    const globalScript = document.querySelector('script[src="https://trafficscanner.pl/ws2/script/3729/koniec.js"]');
    if(globalScript){
      globalScript.remove()
    }
    const script = document.createElement('script');
    script.src = 'https://trafficscanner.pl/ws2/script/3605/koniec.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);
    return(()=>{
      document.body.removeChild(script);
      document.body.appendChild(globalScript);
    })
  },[])


  return (
    <div className="landingPageAfi">
      <div className="landingPageAfi__top">
        <div className="landingPageAfi__left">
          <Helmet>
            <link rel="canonical" href={window.location.href}/>
          </Helmet>
          <Media.DesktopOnly>
            <img
              src={isWebpSupported ? backgroundImageWebWebP : backgroundImageWeb}
              alt="afi car"
              className="landingPageAfi__background-image"
            />
          </Media.DesktopOnly>
          <Media.MobileOnly>
            <img
              src={isWebpSupported ? backgroundImageMobileWebP : backgroundImageMobile}
              alt="afi car"
              className="landingPageAfi__background-image"
            />
          </Media.MobileOnly>
          <Media.DesktopOnly>
            <div className="landingPageAfi__bottom-text">
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
                marginBottom={16}
              >
                mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z o.o. 
                Informacja handlowa nie stanowi oferty w rozumieniu przepisów Kodeksu Cywilnego, ma charakter jedynie
                poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa taka może być zawarta w formie pisemnej
                pod rygorem nieważności, nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o. pozytywnej decyzji o
                finansowaniu. Prezentacja pojazdów na stronie nie gwarantuje ich dostępności. Proces weryfikacji
                dostępności odbywa się po wysłaniu formularza kontaktowego. Informujemy, iż mLeasing Sp. z o.o. z
                siedzibą w Warszawie przy ul. Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem
                Pani(a) danych osobowych. Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez
                mLeasing Sp. z o.o. w celach umożliwienia korzystania Pani(u) z Serwisu mAuto na warunkach zgodnych z
                Regulaminem, w tym w celach przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub
                numer telefonu, w celach marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach
                statystycznych i analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą
                przysługuje prawo żądania od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia,
                ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn
                związanych ze szczególną sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby
                marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem
                bezpośrednim. Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie
                danych, w każdym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie
                zgody przed jej cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez
                mLeasing Danych osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu
                Ochrony Danych Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów.
                Więcej informacji o zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem{" "}
                <a href={"mailto:daneosobowe@mleasing.pl"}>daneosobowe@mleasing.pl</a> lub znajdziesz w{" "}
                <a onClick={downloadSiteRules} target="_blank">
                  Regulaminie Serwisu.
                </a>
              </Text>
            </div>
          </Media.DesktopOnly>
        </div>

        <div className="landingPageAfi__contact-form-section">
          <ContactFormForm isSpecialLandingPage lpAfi />
        </div>
        <Media.MobileOnly>
          <div className="landingPageAfi__bottom-text">
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
              marginBottom={16}
            >
              mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z o.o.
              Informacja handlowa nie stanowi oferty w rozumieniu przepisów Kodeksu Cywilnego, ma charakter jedynie
              poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa taka może być zawarta w formie pisemnej pod
              rygorem nieważności, nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o. pozytywnej decyzji o
              finansowaniu. Prezentacja pojazdów na stronie nie gwarantuje ich dostępności. Proces weryfikacji
              dostępności odbywa się po wysłaniu formularza kontaktowego. Informujemy, iż mLeasing Sp. z o.o. z siedzibą
              w Warszawie przy ul. Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem Pani(a)
              danych osobowych. Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez mLeasing Sp.
              z o.o. w celach umożliwienia korzystania Pani(u) z Serwisu mAuto na warunkach zgodnych z Regulaminem, w
              tym w celach przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub numer telefonu, w
              celach marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach statystycznych i
              analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą przysługuje prawo żądania
              od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
              prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn związanych ze szczególną
              sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby marketingu, w tym
              profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.
              Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie danych, w każdym
              momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
              cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez mLeasing Danych
              osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych
              Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów. Więcej informacji o
              zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem{" "}
              <a href={"mailto:daneosobowe@mleasing.pl"}>daneosobowe@mleasing.pl</a> lub znajdziesz w{" "}
              <a onClick={downloadSiteRules} target="_blank">
                Regulaminie Serwisu.
              </a>
            </Text>
          </div>
        </Media.MobileOnly>
      </div>
    </div>
  );
};

export default LandingPageAfi;
