import React, { useEffect } from "react";
import "./index.scss";
import ContactFormForm from "../../modules/ContactFormSection";
import { Text, Media } from "@mbank-design/design-system/components";
import { FontColor } from "@mbank-design/design-system/enums";
import { FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";

import backgroundImageWeb from "./img/bmw_web.png";
import backgroundImageMobile from "./img/bmw_mobile.png";
import backgroundImageWebWebP from "./img/bmw_web.webp";
import backgroundImageMobileWebP from "./img/bmw_mobile.webp";
import { clearCampaignData, setCampaignData } from "../../common/campaign-data-setter";
import { Helmet } from "react-helmet";

const LandingPageBMW = (props) => {
  const isWebpSupported = webpSupported();

  useEffect(() => {
    clearCampaignData();
    setCampaignData();
  }, []);

  return (
    <div className="landingPageBMW">
      <div className="landingPageBMW__top">
        <div className="landingPageBMW__left">
          <Helmet>
            <link rel="canonical" href={window.location.href}/>
          </Helmet>
          <Media.DesktopOnly>
            <img
              src={isWebpSupported ? backgroundImageWebWebP : backgroundImageWeb}
              alt="bmw car"
              className="landingPageBMW__background-image"
            />
          </Media.DesktopOnly>
          <Media.MobileOnly>
            <img
              src={isWebpSupported ? backgroundImageMobileWebP : backgroundImageMobile}
              alt="bmw car"
              className="landingPageBMW__background-image"
            />
          </Media.MobileOnly>
          <Media.DesktopOnly>
            <div className="landingPageBMW__bottom-text">
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
              >
                Łączny koszt 1549 zł netto dotyczy modelu BMW serii 3 przy założeniu: opłata wstępna 28 498 zł, okres
                finansowania 48 miesiące, całkowity limit przebiegu 40 tys. km. Łączny koszt obejmuje pakiet serwisowy
                Basic oraz ratę za używanie i ubezpieczenie komunikacyjne AC, OC, NNW. Rata serwisowa oraz rata za
                używanie i ubezpieczenie fakturowane odrębnie. Koszt finansowania ubezpieczenia – wyliczony stopą roczną
                w wysokości 2,5% – rozliczany dodatkową fakturą.
              </Text>
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
              >
                mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z
                o.o. Informacja jest informacją handlową i nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego.
                Ma charakter jedynie poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa wymaga formy pisemnej
                pod rygorem nieważności i może być zawarta nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o.
                pozytywnej decyzji o finansowaniu.
              </Text>
            </div>
          </Media.DesktopOnly>
        </div>

        <div className="landingPageBMW__contact-form-section">
          <ContactFormForm isSpecialLandingPage lpBMW />
        </div>
        <Media.MobileOnly>
          <div className="landingPageBMW__bottom-text">
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
            >
              Łączny koszt 1549 zł netto dotyczy modelu BMW serii 3 przy założeniu: opłata wstępna 28 498 zł, okres
              finansowania 48 miesiące, całkowity limit przebiegu 40 tys. km. Łączny koszt obejmuje pakiet serwisowy
              Basic oraz ratę za używanie i ubezpieczenie komunikacyjne AC, OC, NNW. Rata serwisowa oraz rata za
              używanie i ubezpieczenie fakturowane odrębnie. Koszt finansowania ubezpieczenia – wyliczony stopą roczną w
              wysokości 2,5% – rozliczany dodatkową fakturą.
            </Text>
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
            >
              mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z o.o.
              Informacja jest informacją handlową i nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego. Ma
              charakter jedynie poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa wymaga formy pisemnej pod
              rygorem nieważności i może być zawarta nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o. pozytywnej
              decyzji o finansowaniu.
            </Text>
          </div>
        </Media.MobileOnly>
      </div>
    </div>
  );
};

export default LandingPageBMW;