import React from "react";
import PropTypes from "prop-types";
import {
  FontColor,
  FontSize,
  FontWeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import {
  Text,
  Icon,
  Button,
  Box,
} from "@mbank-design/design-system/components";
import { Link } from "react-router-dom";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";
import {
  Heart,
  Bell,
  ShoppingBag,
  CupStar,
  Gear,
  ChevronRight,
  PhoneHandle,
  MagnifyingGlass,
  ChevronDown,
  Standby,
} from "@mbank-design/design-system/icons";
import "./index.scss";
import NavBarLinkWithIcon from "../../parts/NavBarLinkWithIcon";
import { accountLogout } from "../../../services";
import { LocalStorageService } from "../../../services/jwt";
import NavBarLogOutLinkWithIcon from "../../parts/NavBarLogOutLinkWithIcon";

const LoggedLinks = (props) => (
  <>
    <div className="logged-container">
      <Text as="h2">{localStorage.getItem("userName")}</Text>
      <NavBarLinkWithIcon
        iconComponent={Bell}
        text="wkrótce w sprzedaży"
        linkString="/wkrotce-w-sprzedazy"
      />
      <NavBarLinkWithIcon
        iconComponent={ShoppingBag}
        text="licytujesz"
        linkString="/licytowanie"
      />
      <NavBarLinkWithIcon
        iconComponent={CupStar}
        text="wygrane aukcje"
        linkString="/wygrane-aukcje"
      />
    </div>
    <div className="logged-container">
      <NavBarLinkWithIcon
        iconComponent={Gear}
        text="ustawienia"
        linkString="/ustawienia"
      />
      <div
        className="logout"
        onClick={() => {
          accountLogout().then((res) => {
            LocalStorageService.clearToken();
            props.props.setIsLogged(false);
            window.location.href = "/login";
          });
        }}
      >
        <NavBarLogOutLinkWithIcon
          iconComponent={Standby}
          text="wyloguj się"
        />
      </div>
    </div>
  </>
);
const NavBarLinks = (props) => {
  NavBarLinks.propTypes = {
    isMobile: PropTypes.bool,
    isLogged: PropTypes.bool,
    isDesktopLoggedMenuOpen: PropTypes.bool,
    isDarkMode: PropTypes.bool,
    favoriteCarAmount: PropTypes.number,
    offerSublinksExpanded: PropTypes.bool,
    brandSublinksExpanded: PropTypes.bool,
    toggleOfferSublinksExpansion: PropTypes.func,
    toggleBrandSublinksExpansion: PropTypes.func,
    collapseSublinks: PropTypes.func,
    toggleSearchMenuState: PropTypes.func
  };

  return (
    <>
      {props.isLogged && props.isMobile && <LoggedLinks props={props} />}
      {(!props.isMobile || (!props.offerSublinksExpanded && !props.brandSublinksExpanded)) && (
        <div className="links">
          <Box
            marginRight={24} 
            className="clickable flex-start"
            gap={4}
            onClick={() => props.toggleOfferSublinksExpansion()}>
            <Text
              as="div"
              className={'centered link-with-arrow' + (props.offerSublinksExpanded ? " nav-extended" : "")}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={ props.offerSublinksExpanded ? "endeavourBlueUI" : FontColor.DARK}
            >
              oferta
            </Text>
            <div className={"centered rotable " + (props.offerSublinksExpanded ? "upside-down" : "")}>
              <Icon iconComponent={props.isMobile ? ChevronRight : ChevronDown} primaryColor={IconColor.SECONDARY_CONTRAST} size={IconSize.SMALL} />
            </div>
          </Box>
          <Box 
            marginRight={24} 
            className="clickable flex-start"
            gap={4}
            onClick={() => props.toggleBrandSublinksExpansion()}>
            <Text
              as="div"
              className={'centered link-with-arrow' + (props.brandSublinksExpanded ? " nav-extended highlighted" : "")}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={ props.brandSublinksExpanded ? "endeavourBlueUI" : FontColor.DARK}
            >
              marki
            </Text>
            <div className={"centered rotable " + (props.brandSublinksExpanded ? "upside-down " : "")}>
              <Icon iconComponent={props.isMobile ? ChevronRight : ChevronDown} primaryColor={IconColor.SECONDARY_CONTRAST} size={IconSize.SMALL} />
            </div>
          </Box>
          <Link to="/elektryfikacja-floty"
            onClick={() => props.collapseSublinks()}
          >
            <Text
              as="p"
              className={window.location.href.includes("/elektryfikacja-floty") ? "nav-extended highlighted" : ""}
              marginRight={24}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={window.location.href.includes("/elektryfikacja-floty") ? "endeavourBlueUI" : FontColor.DARK}
            >
              elektryfikacja floty
            </Text>
          </Link>
          <Link to="/o-nas"
            onClick={() => props.collapseSublinks()}
          >
            <Text
              as="p"
              className={window.location.href.includes("/o-nas") ? "nav-extended highlighted" : ""}
              marginRight={24}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={window.location.href.includes("/o-nas") ? "endeavourBlueUI" : FontColor.DARK}
            >
              o nas
            </Text>
          </Link>
          <Link to="/kontakt"
            onClick={() => props.collapseSublinks()}>
            <Text
              as="p"
              className={window.location.href.includes("/kontakt") ? "nav-extended highlighted" : ""}
              marginRight={24}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={window.location.href.includes("/kontakt") ? "endeavourBlueUI" : FontColor.DARK}
            >
              kontakt
            </Text>
          </Link> 
          {!props.isMobile&&
           <Link to="/ulubione/oferty"
           onClick={() => props.collapseSublinks()}>
           <div className="viewed-cars-button-container centered">
             <Text
               as="p"
               className={
                 window.location.href.includes("/ulubione") ? "nav-extended highlighted" : ""
               }
               marginRight={24}
               fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
               color={window.location.href.includes("/ulubione") ? "endeavourBlueUI" : FontColor.DARK}
             >
               ulubione
             </Text>
             {props.favoriteCarAmount !== undefined &&
               props.favoriteCarAmount > 0 && (
                 <div className="viewed-cars-count-circle">
                   <Text
                     fontSize={FontSize.SIZE_12}
                     color={FontColor.white30}
                     as="span"
                   >
                     {props.favoriteCarAmount}
                   </Text>
                 </div>
               )}
           </div>
         </Link>}
          <a href={"https://mauto.pl/blog/"}
            onClick={() => props.collapseSublinks()}>
            <Text
              as="p"
              className={window.location.href.includes("/blog") ? "nav-extended highlighted" : ""}
              marginRight={24}
              fontWeight={props.isMobile ? FontWeight.WEIGHT_500 : FontWeight.WEIGHT_600}
              color={window.location.href.includes("/blog") ? "endeavourBlueUI" : FontColor.DARK}
            >
              blog
            </Text>
          </a> 
        </div>
      )}
      {!props.isMobile && (
        <div className="buttons"
          onClick={() => props.collapseSublinks()}>
            <div
              onClick={()=>props.toggleSearchMenuState()}
              className="buttons__icon"
            >
              <Icon
              iconComponent={MagnifyingGlass}
              primaryColor={IconColor.SECONDARY_CONTRAST}
              />
            </div>
            <Link 
              to="/kontakt"
              className="buttons__icon">
                <div
                  className="buttons__icon"
                >
                  <Icon
                  iconComponent={PhoneHandle}
                  primaryColor={IconColor.SECONDARY_CONTRAST}
                  />
                  <Text
                  styledAs={"h4"}
                  color={FontColor.blue30}
                  >
                    605 41 50 50
                  </Text>
                </div>
            </Link>
          {props.isLogged && !props.isMobile && (
            <>
              <div
                className="logged-desktop-button"
                onClick={props.toggleLoggedMenu}
              >
                <Text
                  as="p"
                  fontWeight={FontWeight.WEIGHT_600}
                  color={
                    FontColor.DARK
                  }
                >
                  {localStorage.getItem("userName")}
                </Text>{" "}
                <Icon iconComponent={props.isMobile ? ChevronRight : ChevronDown} primaryColor={IconColor.SECONDARY_CONTRAST} />
              </div>
              {props.isDesktopLoggedMenuOpen && (
                <div className="desktop-logged-nav">
                  <LoggedLinks props={props} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default NavBarLinks;
