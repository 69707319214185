import React, { useEffect } from "react";
import "./index.scss";
import ContactFormForm from "../../modules/ContactFormSection";
import { Text, Media } from "@mbank-design/design-system/components";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";

import backgroundImageWeb from "./img/targi_web.png";
import backgroundImageMobile from "./img/targi_mobile.png";
import backgroundImageWebWebP from "./img/targi_web.webp";
import backgroundImageMobileWebP from "./img/targi_mobile.webp";
import { FontColor, FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { clearCampaignData, setCampaignData } from "../../common/campaign-data-setter";
import { Helmet } from "react-helmet";

const LandingPageTargi = () => {
  const isWebpSupported = webpSupported();

  const bottomText='Informujemy, iż mLeasing Sp. z o.o. z siedzibą w Warszawie przy ul. Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem Pani(a) danych osobowych. Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez mLeasing Sp. z o.o. w celach przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub numer telefonu, w celach marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach statystycznych i analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą przysługuje prawo żądania od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn związanych ze szczególną sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim. Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie danych, w każdym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez mLeasing Danych osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów. Więcej informacji o zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem daneosobowe@mleasing.pl.'

  useEffect(()=>{
    // insertScript();
    clearCampaignData();
    setCampaignData();
  },[])

  const insertScript=() => {
    const script = document.createElement('script');
    script.innerHTML  = `(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
      .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
      n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
      (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
      ml('account', '924563');`;
    document.head.insertBefore(script, document.head.lastElementChild);
    return(()=>{
      document.head.removeChild(script);
    })
  }


  return (
    <div className="landingPageTargi">
      <div className="landingPageTargi__top">
        <div className="landingPageTargi__left">
          <Helmet>
          <link rel="canonical" href={window.location.href}/>
          </Helmet>
          <Media.DesktopOnly>
            <img
              src={isWebpSupported ? backgroundImageWebWebP : backgroundImageWeb}
              alt="tesla car"
              className="landingPageTargi__background-image"
            />
          </Media.DesktopOnly>
          <Media.MobileOnly>
            <img
              src={isWebpSupported ? backgroundImageMobileWebP : backgroundImageMobile}
              alt="tesla car"
              className="landingPageTargi__background-image"
            />
          </Media.MobileOnly>
          <Media.DesktopOnly>
            <div className="landingPageTargi__bottom-text">
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
                marginBottom={16}
              >
                {bottomText}
              </Text>
            </div>
          </Media.DesktopOnly>
        </div>

        <div className="landingPageTargi__contact-form-section">
          <ContactFormForm isSpecialLandingPage lpTargi />
        </div>
        <Media.MobileOnly>
          <div className="landingPageTargi__bottom-text">
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
              marginBottom={16}
            >
              {bottomText}
            </Text>
          </div>
        </Media.MobileOnly>
      </div>
    </div>
  );
};

export default LandingPageTargi;
