import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HowToBuySection from "../../modules/HowToBuySection";
import MainProsSection from "../../modules/MainProsSection";
import AdditionalPackagesSection from "../../modules/AdditionalPackagesSection";
import CharacteristicFeaturesList from "../../modules/CharacteristicFeaturesList";
import ContactFormDetails from "../../modules/ContactFormDetailsSection";
import { Amount, Box, Button, Icon, Tag, Text, Media, Illustration } from "@mbank-design/design-system/components";
import VehicleGallery from "../../modules/VehicleGallery";
import MainContactSection from "../../modules/MainContactSection";
import {
  ArrowLeft,
  Calendar,
  Car,
  CarHuman,
  Copy,
  Envelope,
  FuelPump,
  Gearbox,
  Lock,
  PhoneHandle,
  Engine,
  Leaf,
  InfoRound
} from "@mbank-design/design-system/icons";
import "./index.scss";
import Calculator from "../../modules/Calculator";
import CarDetailsInformationTile from "../../parts/CarDetailsInformationTile";
import { LoadingSpinner } from "../../parts/LoadingSpinner";
import {
  getLeaseDetailsData,
  getLeasingPrice,
  getLongTimeRentPrice,
  getNewDetailsData,
  getAuctionsDetailsData,
  getSimplifiedVehicleData,
  leasing105Check,
  leasing105Data,
  getFavoriteAllVehicles
} from "../../../services";
import SectionTitleComponent from "../../modules/SectionTitleComponent";
import CarOfferTile from "../../modules/CarOfferTile";
import CarsSection from "../../modules/CarsSection";
import {
  getCalculatorProperties,
  getRecommendedAfterLeaseVehicles,
  getRecommendedNewVehicles
} from "../../../services/index";
import { createPackagesDataForNewCars } from "../../common/new-calc-additional-packages";
import { MobileSearchContext, ToastContext } from "../../../scripts/commons/contexts";
import { Stack } from "@mbank-design/design-system/components/Stack/Stack";
import uuid from "react-uuid";
import { literalCreator } from "../../../literals/literals";
import { modifyUsedCalcPackages } from "../../common/modify-used-calc-packages";
import PromotedOfferSticker from "../../parts/PromotedOfferSticker";
import * as moment from "moment";
import "moment/locale/pl";
import { setCampaignData, setVehicleViewContentInsightTag } from "../../common/campaign-data-setter";
import { endpointGuard } from "../../../scripts/commons/guard/endpoint-guard";
import DetailsPageHelmet from "../../parts/DetailsPageHelmet";
import {
  cutIdFromMathParams,
  imitationClickForCloseAllTooltips,
  replaceSpecialCharsets
} from "../../../scripts/commons/common";
import { extraInsuranceTooltipText, PACKAGE } from "../../../constants";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";
import { ManDreamSavingGoalsMass } from "@mbank-design/design-system/illustrations";
import MobileMainPageSearch from "../../modules/MobileMainPageSearch";
import DetailWhyUs from "../../parts/DetailWhyUs";
import Breadcrumbs from "../../parts/Breadcrumbs";
import EquipmentList from "../../modules/EquipmentList";
import DetailNavigationContainer from "../../parts/DetailNavigationContainer";
import { DetailsCertificateWidget } from "../../parts/DetailsCertificateWidget";
import DetailsNavigation from "../../parts/DetailsNavigation";
import CarsCarousel from "../../parts/CarsCarousel";
import ButtonCopyLink from "../../parts/ButtonCopyLink";
import ButtonFavorite from "../../parts/ButtonFavorite";
import SpecialPromotions from "../../parts/SpecialPromotions";
import FavouriteButton from "../../modules/FavouriteButton";
import {
  generateCalculatorData,
  getInstallmentLeasingPriceBySelectedOptions,
  getInstallmentRentPriceBySelectedOptions,
  getInsurancePackagePriceByLeasingPeriods,
  getInsurancePackagePriceByRentPeriods
} from "../../common/calculator";
import { ContactModal } from "../../modules/ContactModal";
import {
  FontColor,
  FontSize,
  FontWeight,
  LineHeight
} from "@mbank-design/design-system/design-tokens/general/typography";
import { Alignment, ButtonType, Direction, IconColor, IllustrationSize } from "@mbank-design/design-system/enums";
import ReservedLabel from "../../parts/ReservedLabel";
import seoModels from "./seoModels";
import AwardBanner from "../../modules/AwardBanner";
import PromoBanner from "../../parts/PromoBanner";

moment.locale("en");

const charListRef = React.createRef();

class DetailsPage extends Component {
  state = {
    auctionId: parseInt(cutIdFromMathParams(this.props.match.params.id)),
    details: null,
    calculatorData: null,
    calculatorResult: null,
    calculatorRaw: null,
    technicalInformationList: [],
    paramsValue: "",
    backgroundPhoto: "",
    pageType: "",
    currentlySelectedVariant: 0,
    currentlySelectedRecommendedVehicle: 0,
    isCompany: true,
    recommendedCars: [],
    contactUrl: null,
    showCalc: false,
    calculatorTabSelected: 1,
    showLoader: false,
    favouriteCarData: {},
    isCalculatorLoading: false,
    isContactModalOpen: false,
    urlObject: "",
    isShowSlider: false,
    calcCurrentPrices: {},
    modalButtonData:{},
    urlToContactSection:'',
  };

  componentDidMount() {
    // LinkedIn Insight Tag
    setVehicleViewContentInsightTag();
    this.setState({ showLoader: true });
    literalCreator.getLiterals().then((res) => {
      this.setState({ literals: literalCreator.data }, () => {
        this.setState({ showLoader: false });
        window.scrollTo(0, 0);
        this.initDetails();
      });
    });
    setCampaignData();
    if (document.getElementById("root")) {
      document.getElementById("root").scrollTop = 0;
    }
  }
   updateChildData = (newData) => {
    this.setState({ urlToContactSection: newData });
  };

  getFavouriteCarData = () => {
    getFavoriteAllVehicles({
      IdArr: [this.state.auctionId]
    }).then((res) => {
      let favouriteCarData;
      if (this.state.pageType === "afterLease") {
        favouriteCarData = res.data.AfterLease[0];
      } else if (this.state.pageType === "new") {
        favouriteCarData = res.data.NewVehicles[0];
      }
      this.setState({ favouriteCarData });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initDetails();
    }
  }

  initDetails() {
    localStorage.setItem("previousViewedCarId", cutIdFromMathParams(this.props.match.params.id));

    let pageType = "";
    const pathName = this.props.match.path.split("/")[1];

    if (pathName === "nowe-samochody") {
      pageType = "new";
    } else if (pathName === "samochody-poleasingowe") {
      pageType = "afterLease";
    }

    this.setState(
      {
        calculatorResult: null,
        calculatorData: null,
        details: null,
        pageType: pageType
      },
      async () => {
        await this.getCarData();
        await this.getRecommendedVehicles();
        await this.getFavouriteCarData();
      }
    );
  }

  componentWillUnmount() {
    this.props.setDarkTheme && this.props.setDarkTheme(false);
  }

  setShowCalc(val) {
    this.setState({ showCalc: true });
  }

  setCalculatorTabSelected = this.setCalculatorTabSelected.bind(this);
  setCalculatorTabSelected(tab) {
    this.setState({ ...this.state, calculatorTabSelected: tab });
  }

  getPrice(e) {
    this.setState({ getPriceLoaded: true });
    const vehicleDetails = this.state.details[this.state.currentlySelectedVariant];
    const { AdditionalServices, ...packagelessObj } = e.Filters;
    const insuranceValueCheck = vehicleDetails.Calculator && vehicleDetails.Calculator.Insurance && vehicleDetails.Calculator.Insurance.every(item => item.Value === 0)

    if (e.FinancingMethod === 1 && vehicleDetails.IsRentAvailable) {
      getLongTimeRentPrice({
        AdditionalServices: [],
        ...packagelessObj,
        CalculatorLongTermTypeId: vehicleDetails.CalculatorLongTermTypeId,
        isNew: this.state.pageType === "new",
        isElectric: vehicleDetails.IsElectric,
        isTruck: vehicleDetails.IsTruck,
        AuctionId: this.state.calculatorData.AuctionId
      }).then((res) =>{
        this.setState({
          calculatorRaw: res.data
        })}
      );
    
      (this.state.pageType !== "new" || !vehicleDetails.Calculator.RentPeriods.length || insuranceValueCheck) && 
      getLongTimeRentPrice({
        ...e.Filters,
        CalculatorLongTermTypeId: vehicleDetails.CalculatorLongTermTypeId,
        isNew: this.state.pageType === "new",
        isElectric: vehicleDetails.IsElectric,
        isTruck: vehicleDetails.IsTruck,
        AuctionId: this.state.calculatorData.AuctionId
      }).then((res) => {
        this.setState(
          {
            calculatorResult: generateCalculatorData(res.data, e, vehicleDetails, this.state.pageType === "new"),
            latestCalcConfig: e,
          },
          () => {
            if (this.state.pageType === "new") {
              this.setPackagesPricesForNewCarRent(
                this.state.calculatorResult?.InsurancePriceInfo,
                this.state.calculatorResult?.InsuranceAdditionalText
              );
            } else if (this.state.pageType === "afterLease") {
              this.setCorrectInsurancePackage(
                this.state.calculatorResult?.InsurancePriceInfo,
                this.state.calculatorResult?.InsuranceAdditionalText
              );
            }
          }
        );
      });
      vehicleDetails.Calculator &&
      vehicleDetails.Calculator.RentPeriods.length &&
      this.setState(
        {
          calculatorResult: generateCalculatorData(null, e, vehicleDetails, this.state.pageType === "new"),
          latestCalcConfig: e
        },
        () => {
          if (this.state.pageType === "new") {
            this.setPackagesPricesForNewCarRent(
              this.state.calculatorResult?.InsurancePriceInfo,
              this.state.calculatorResult?.InsuranceAdditionalText
            );
          } else if (this.state.pageType === "afterLease") {
            this.setCorrectInsurancePackage(
              this.state.calculatorResult?.InsurancePriceInfo,
              this.state.calculatorResult?.InsuranceAdditionalText
            );
          }
        }
      );
    } else if (e.FinancingMethod === 2 && vehicleDetails.IsLeasingAvailable) {
      (this.state.pageType !== "new" || !vehicleDetails.Calculator.LeasingPeriods.length) &&
      getLeasingPrice({
        AdditionalServices: [],
        ...packagelessObj,
        CalculatorLongTermTypeId: vehicleDetails.CalculatorLeasingTypeId,
        isNew: this.state.pageType === "new",
        isElectric: vehicleDetails.IsElectric,
        isTruck: vehicleDetails.IsTruck,
        AuctionId: this.state.calculatorData.AuctionId
      }).then((res) =>
        this.setState({
          calculatorRaw: res.data
        })
      );
      (this.state.pageType !== "new" || !vehicleDetails.Calculator.LeasingPeriods.length || insuranceValueCheck) &&
      getLeasingPrice({
        ...e.Filters,
        CalculatorLeasingTypeId: vehicleDetails.CalculatorLeasingTypeId,
        isNew: this.state.pageType === "new",
        isElectric: vehicleDetails.IsElectric,
        isTruck: vehicleDetails.IsTruck,
        AuctionId: this.state.calculatorData.AuctionId
      }).then((res) => {
        this.setState(
          {
            calculatorResult: generateCalculatorData(res.data, e, vehicleDetails, this.state.pageType === "new"),
            latestCalcConfig: e,
          },
          () => {
            if (this.state.pageType === "new") {
              this.setPackagesPricesForNewCarLease(
                this.state.calculatorResult?.InsurancePriceInfo,
                this.state.calculatorResult?.InsuranceAdditionalText
              );
            } else if (this.state.pageType === "afterLease") {
              this.setCorrectInsurancePackage(
                this.state.calculatorResult?.InsurancePriceInfo,
                this.state.calculatorResult?.InsuranceAdditionalText
              );
            }
          }
        );
      });
      vehicleDetails.Calculator &&
      vehicleDetails.Calculator.LeasingPeriods.length &&
      this.setState(
        {
          calculatorResult: generateCalculatorData(null, e, vehicleDetails, this.state.pageType === "new"),
          latestCalcConfig: e
        },
        () => {
          if (this.state.pageType === "new") {
            this.setPackagesPricesForNewCarLease(
              this.state.calculatorResult?.InsurancePriceInfo,
              this.state.calculatorResult?.InsuranceAdditionalText
            );
          } else if (this.state.pageType === "afterLease") {
            this.setCorrectInsurancePackage(
              this.state.calculatorResult?.InsurancePriceInfo,
              this.state.calculatorResult?.InsuranceAdditionalText
            );
          }
        }
      );
    }
  }

  handleNewCalcObject(e) {
    this.setState({ latestCalcConfig: e }, () => {});
  }

  setCorrectInsurancePackage(insurancePrice, insuranceAdditionalText) {
    const packages = [...this.state.calculatorData.AdditionalServiceItems];
    packages.find((x) => x.Value === PACKAGE.INSURANCE).Price = insurancePrice;

    if (insuranceAdditionalText) {
      let indexOfInsurance = packages.findIndex((el) => el.Value === PACKAGE.INSURANCE);

      packages[indexOfInsurance].EkstraTooltip = ` ${insuranceAdditionalText}`;
    }

    this.setState({
      calculatorData: {
        ...this.state.calculatorData,
        AdditionalServiceItems: packages
      }
    });
  }

  async getRecommendedVehicles() {
    let vehiclesArray = [];
    // new
    if (this.state.pageType === "new") {
      await getRecommendedNewVehicles(cutIdFromMathParams(this.props.match.params.id)).then((response) => {
        vehiclesArray = Object.values(response.data);
        this.setState({
          recommendedCars: vehiclesArray.filter((vehicle) => vehicle.AuctionId !== 0)
        });
      });
    }

    // after lease
    if (this.state.pageType === "afterLease") {
      getRecommendedAfterLeaseVehicles(cutIdFromMathParams(this.props.match.params.id)).then((response) => {
        vehiclesArray = Object.values(response.data);
        this.setState({
          ...this.setState,
          recommendedCars: vehiclesArray.filter((vehicle) => vehicle.AuctionId !== 0)
        });
      });
    }
  }

  async getCarData() {
    if (this.props.match.url.includes("/new")) {
      await this.renderUrlFromSimplifiedDetails("new");
    } else if (this.props.match.url.includes("/afterLease")) {
      await this.renderUrlFromSimplifiedDetails("afterLease");
    } else if (this.props.match.url.includes("/auction")) {
      await this.renderUrlFromSimplifiedDetails("auction");
    }

    this.replaceWrongSymbolsFromUrl();

    // after lease
    if (this.state.pageType === "afterLease") {
      getLeaseDetailsData({
        id: cutIdFromMathParams(this.props.match.params.id)
      })
        .then((res) => {
          this.setLocalStorageHistory(res);
          this.setState(
            {
              ...this.state,
              details: [
                {
                  ...res.data
                }
              ],
              isCompany: !(localStorage.getItem("isCompany") === "false")
            },
            () => {
              localStorage.removeItem("isCompany");
              this.fillTechnicalInformationsList();
              this.getCalculatorData(res.data.MaxAvailableLeasingPeriod, res.data.MaxAvailableRentPeriod);
            }
          );
        })
        .catch(() => {
          window.location.href = "/samochody-poleasingowe";
        });
    }

    // new
    if (this.state.pageType === "new") {
      await getNewDetailsData({
        id: cutIdFromMathParams(this.props.match.params.id)
      })
        .then(async (res) => {
          // for tests
          // res.data.Calculator.Insurance[0].Value = res.data.Calculator.Insurance[0].Value || 1001;
          // res.data.Calculator.Insurance[1].Value = res.data.Calculator.Insurance[1].Value || 2001;
          // res.data.Calculator.Insurance[2].Value = res.data.Calculator.Insurance[2].Value || 3001;
          this.setLocalStorageHistory(res);
          if (res.status >= 200 && res.status <= 299) {
            const details = res.data;
            if (details.Calculator === null) {
              details.IsRentAvailable = false;
            }

            this.setState(
              {
                ...this.state,
                details: [
                  {
                    ...details
                  }
                ],
                isCompany: true
              },
              () => {
                this.fillTechnicalInformationsList();
                this.getCalculatorData(res.data.MaxAvailableLeasingPeriod, res.data.MaxAvailableRentPeriod);
              }
            );
          } else {
            endpointGuard(res.data.ErrorCode);
          }
        })
        .catch(() => {});
    }
  }

  characteristicItemHandler(arrayOfStrings) {
    // replace array of Strings with obj {label: string, url: string}
    // we're using it inside characteristicFeaturesList
    let arr = [];
    if (arrayOfStrings && arrayOfStrings.length) {
      for (let item of arrayOfStrings) {
        arr.push({ label: item });
      }
    }
    return arr;
  }

  replaceWrongSymbolsFromUrl() {
    if (this.props.match.params.id !== this.props.match.params.id.toLowerCase()) {
      this.props.history.push(this.props.location.pathname.toLowerCase() + this.props.location.search);
    }

    if (this.props.match.params.id.includes("_")) {
      this.props.history.push(this.props.match.url.replaceAll("_", "-"));
    } else if (
      this.props.match.params.id.lastIndexOf("-id-") > 0 &&
      !this.props.match.params.id.substring(0, this.props.match.params.id.lastIndexOf("-id-")).includes("-")
    ) {
      window.location.href = "/404";
    }
  }

  async renderUrlFromSimplifiedDetails(type) {
    if (isNaN(parseInt(this.props.match.params.id))) {
      this.props.history.push("/nowe-samochody/" + this.props.match.params.id + this.props.location.search);
    } else {
      const oldPathName = this.props.match.path.split("/")[1];
      let pathName = "nowe-samochody";
      switch (oldPathName) {
        case "new":
          pathName = "nowe-samochody";
          break;
        case "afterLease":
          pathName = "samochody-poleasingowe";
          break;
        case "auction":
          pathName = "aukcje-samochodowe";
          break;
        default:
          pathName = "nowe-samochody";
          break;
      }
      await getSimplifiedVehicleData(this.props.match.params.id, type)
        .then(async (res) => {
          let link = "/" + pathName + "/" + res.data.Make + "-" + res.data.Model + "-id-" + this.props.match.params.id;
          this.props.history.push(`${link}${this.props.location.search}`);
        })
        .catch(() => {
          window.location.href = "/" + pathName;
        });
    }
  }

  getCalculatorData(maxAvailableLeasingPeriod, maxAvailableRentPeriod) {
    if (!this.state.details) return;

    const vehicleDetails = this.state.details[this.state.currentlySelectedVariant];

    this.setState({ isCalculatorLoading: true }, () => {
      getCalculatorProperties(maxAvailableLeasingPeriod, maxAvailableRentPeriod).then((res) => {
        if (this.state.getPriceLoaded) return;
        if (res.status >= 200 && res.status <= 299) {
          let additionalPackages = modifyUsedCalcPackages(
            res.data.AdditionalServiceItems,
            this.state.literals,
            vehicleDetails.IsElectric,
            this.state.pageType === "new"
          );

          const indexOfInsurance = additionalPackages.findIndex((el) => el.Value === PACKAGE.INSURANCE);
          //for vehicle leasing with price more than 325203.25 netto
          if (
            vehicleDetails.TotalPriceNetto > 325203.25 &&
            !additionalPackages[indexOfInsurance].Tooltip.includes(extraInsuranceTooltipText)
          ) {
            additionalPackages[indexOfInsurance].Tooltip += extraInsuranceTooltipText;
          }

          let calculatorData = {};

          if (this.state.pageType === "new") {
            let additionalPackages = createPackagesDataForNewCars(
              vehicleDetails.Calculator.LeasingAdditions,
              this.state.literals,
              vehicleDetails.IsElectric
            );

            let additionalPackagesForNewCarRent = createPackagesDataForNewCars(
              vehicleDetails.Calculator.RentAdditions,
              this.state.literals,
              vehicleDetails.IsElectric,
              this.state.calculatorTabSelected
            );
            const indexOfInsurance = additionalPackagesForNewCarRent.findIndex((el) => el.Value === PACKAGE.INSURANCE);
            //for new vehicle with price more than 325203.25 netto
            if (
              vehicleDetails.TotalPriceNetto > 325203.25 &&
              !additionalPackagesForNewCarRent[indexOfInsurance].Tooltip.includes(extraInsuranceTooltipText)
            ) {
              additionalPackagesForNewCarRent[indexOfInsurance].Tooltip += extraInsuranceTooltipText;
            }

            calculatorData = {
              AdditionalServiceItems: additionalPackages,
              AdditionalServiceItemsForNewCarRent: vehicleDetails.IsRentAvailable
                ? additionalPackagesForNewCarRent
                : null
            };

            if (vehicleDetails.IsLeasingAvailable) {
              if (vehicleDetails.Calculator.LeasingPeriods && vehicleDetails.Calculator.LeasingPeriods.length) {
                calculatorData = {
                  ...calculatorData,
                  LeasingPeriods: vehicleDetails.Calculator.LeasingPeriods,
                  LeasingAdditions: vehicleDetails.Calculator.LeasingAdditions
                };
              } else {
                calculatorData = {
                  ...calculatorData,
                  LeasingPeriods: res.data.LeasingPeriods,
                  LeasingAdditions: res.data.LeasingAdditions
                };
              }
            }

            if (vehicleDetails.IsRentAvailable) {
              calculatorData = {
                ...calculatorData,
                RentPeriods: vehicleDetails.Calculator.RentPeriods,
                RentAdditions: vehicleDetails.Calculator.RentAdditions
              };
            }
          } else {
            calculatorData = {
              AdditionalServiceItems: additionalPackages,
              ...res.data
            };
          }

          this.setState(
            {
              ...this.state,
              calculatorData: {
                ...this.state.calculatorData,
                ...calculatorData,
                PriceBrutto: vehicleDetails.TotalPriceBrutto,
                PriceNetto: vehicleDetails.TotalPriceNetto,
                AuctionId: cutIdFromMathParams(this.props.match.params.id),
                IsRentAvailable: vehicleDetails.IsRentAvailable,
                IsLeasingAvailable: vehicleDetails.IsLeasingAvailable,
                IsCashAvailable: vehicleDetails.IsCashAvailable,
                HP: this.state.pageType === "new" ? vehicleDetails.PowerHP : vehicleDetails.HP
              }
            },
            () => {
              this.setState({ isCalculatorLoading: false });
            }
          );
        } else {
          endpointGuard(res.data.ErrorCode);
        }
      });
    });
  }

  /**
   * return price of rent management basic package for currently selected mileage per year
   * @returns {number} price of rent management basic package
   */
  getRentManagementBasicPackagePriceForNewCarRent() {
    /** @type {{Period: {Name: string,Value: number,Status: boolean,Price: number},Ows: {Ow: {Name: string,Value: number,Status: boolean,Price: number},Mileages: {Name: string,Value: number,Status: boolean.Price: number}[]}[]}} */
    let selectedRentPeriod = this.state.calculatorData.RentPeriods.find(
      (rentPeriod) => rentPeriod.Period.Status === true
    );
    let selectedOws = selectedRentPeriod.Ows.find((ows) => ows.Ow.Status === true);
    let selectedMileage = selectedOws.Mileages.find((mileage) => mileage.Status === true);
    let rentManagementBasicForCurrentlySelectedPeriod = this.state.details[
      this.state.currentlySelectedVariant
    ].Calculator.RentAdditions.Rent_ManagementBasic.find((rmb) => rmb.Period === selectedRentPeriod.Period.Value);
    let rentManagementBasicForCurrentlySelectedPeriodAndMileage =
      rentManagementBasicForCurrentlySelectedPeriod?.Mileages.find((mlg) => mlg.MileageTotal === selectedMileage.Value);

    if (rentManagementBasicForCurrentlySelectedPeriodAndMileage) {
      return rentManagementBasicForCurrentlySelectedPeriodAndMileage.Value;
    } else {
      return null;
    }
  }

  /**
   * return price of rent management comfort package for currently selected mileage per year
   * @returns {number} price of rent management comfort package
   */
  getRentManagementComfortPackagePriceForNewCarRent() {
    /** @type {{Period: {Name: string,Value: number,Status: boolean,Price: number},Ows: {Ow: {Name: string,Value: number,Status: boolean,Price: number},Mileages: {Name: string,Value: number,Status: boolean.Price: number}[]}[]}} */
    let selectedRentPeriod = this.state.calculatorData.RentPeriods.find(
      (rentPeriod) => rentPeriod.Period.Status === true
    );
    let selectedOws = selectedRentPeriod.Ows.find((ows) => ows.Ow.Status === true);
    let selectedMileage = selectedOws.Mileages.find((mileage) => mileage.Status === true);
    let rentManagementComfortForCurrentlySelectedPeriod = this.state.details[
      this.state.currentlySelectedVariant
    ].Calculator.RentAdditions.Rent_ManagementComfort.find((rmb) => rmb.Period === selectedRentPeriod.Period.Value);

    if (rentManagementComfortForCurrentlySelectedPeriod && selectedMileage) {
      return rentManagementComfortForCurrentlySelectedPeriod.Mileages.find(
        (mlg) => mlg.MileageTotal === selectedMileage.Value
      )?.Value;
    } else {
      return null;
    }
  }

  /**
   * return price of tyres basic package for currently selected rent period
   * @returns {number} tyres basic package price
   */
  getTyresBasicPackagePriceForNewCarRent() {
    /** @type {{Period: {Name: string,Value: number,Status: boolean,Price: number},Ows: {Ow: {Name: string,Value: number,Status: boolean,Price: number},Mileages: {Name: string,Value: number,Status: boolean.Price: number}[]}[]}} */
    let selectedRentPeriod = this.state.calculatorData.RentPeriods.find(
      (rentPeriod) => rentPeriod.Period.Status === true
    );

    return this.state.details[this.state.currentlySelectedVariant].Calculator.RentAdditions.Tyres.find(
      (tyre) => tyre.Period === selectedRentPeriod.Period.Value
    ).Value;
  }

  /**
   * Set prices of additionalServiceItems from vehicle details calculator data [for new car long time rent]
   */
  setPackagesPricesForNewCarRent(insurancePriceInfo, insuranceAdditionalText) {
    /**
     * @type {{CantBeDeactivated: boolean, DefaultSelectedForLeasing: boolean, DefaultSelectedForRent: boolean, IconName: string, Indent: boolean, Name: string, Order: number, Price: number, SetSelfAsTrue: boolean, Tooltip: string, Value: number, Visibility: {     packageStateName: string     valueRequired: boolean }}[]}
     */
    let packages = [...this.state.calculatorData.AdditionalServiceItemsForNewCarRent];
    const vehicleDetails = this.state.details[this.state.currentlySelectedVariant];

    if (insuranceAdditionalText) {
      let indexOfInsurance = packages.findIndex((el) => el.Value === PACKAGE.INSURANCE);

      packages[indexOfInsurance].EkstraTooltip = ` ${insuranceAdditionalText}`;
    }

    const insurancePrice =
      insurancePriceInfo === -1 ? getInsurancePackagePriceByRentPeriods(vehicleDetails) : insurancePriceInfo;
    packages.forEach((pkg) => {
      switch (pkg.Value) {
        case PACKAGE.INSURANCE:
          pkg.Price = insurancePrice;
          break;
        case PACKAGE.ASSISTANCE:
          pkg.Price = vehicleDetails.Calculator.RentAdditions.Assistance;
          break;
        case PACKAGE.BASIC_SERVICE:
          pkg.Price = this.getRentManagementBasicPackagePriceForNewCarRent();
          break;
        case PACKAGE.COMFORT_SERVICE:
          pkg.Price = this.getRentManagementComfortPackagePriceForNewCarRent();
          break;
        case PACKAGE.TYRES:
          pkg.Price = this.getTyresBasicPackagePriceForNewCarRent() ?? 0;
          break;
        case PACKAGE.PETROL_CARD:
          pkg.Price = vehicleDetails.Calculator.RentAdditions.PetrolCard;
          break;
      }
    });

    this.setState({
      ...this.state,
      calculatorData: {
        ...this.state.calculatorData,
        AdditionalServiceItemsForNewCarRent: [...packages]
      }
    });
  }

  setPackagesPricesForNewCarLease(insurancePriceInfo, insuranceAdditionalText) {
    let packages = [...this.state.calculatorData.AdditionalServiceItems];
    const vehicleDetails = this.state.details[this.state.currentlySelectedVariant];

    packages.forEach((pkg) => {
      if (pkg.Value === PACKAGE.INSURANCE) {
        const insurancePrice =
          insurancePriceInfo === -1 ? getInsurancePackagePriceByLeasingPeriods(vehicleDetails) : insurancePriceInfo;

        pkg.Price = insurancePrice;
      }
    });

    if (insuranceAdditionalText) {
      let indexOfInsurance = packages.findIndex((el) => el.Value === PACKAGE.INSURANCE);

      packages[indexOfInsurance].EkstraTooltip = ` ${insuranceAdditionalText}`;
    }

    this.setState({
      ...this.state,
      calculatorData: {
        ...this.state.calculatorData,
        AdditionalServiceItems: [...packages]
      }
    });
  }

  setIsCompany(isCompany) {
    this.setState({ isCompany: isCompany });
  }

  fillTechnicalInformationsList() {
    if (!this.state.details) return;

    let list = [];
    const details = this.state.details[this.state.currentlySelectedVariant];
    const isElectric = details.IsElectric;

    list.push({
      label: `marka: ${details.Make}`,
      url: "marki/" + details.Make
    });
    list.push({
      label: `model: ${details.Model}`,
      url: `marki/${details.Make}-${details.Model}`
    });
    list.push({
      label: `typ: ${replaceSpecialCharsets(details.Type)}`,
      url: null
    });
    list.push({
      label: `rok: ${details.Year}`,
      url: null
    });
    if (this.state.pageType !== "new") {
      list.push({
        label: `przebieg: ${details.Mileage} km`,
        url: null
      });
    }
    list.push({
      label: `rodzaj paliwa: ${isElectric ? `samochód elektryczny` : details.FuelTypeFullName.toLowerCase()}`,
      url: isElectric ? `samochody-elektryczne` : null
    });
    if (this.state.pageType !== "new") {
      list.push({
        label: `VIN: ${details.VIN}`,
        url: null
      });
    }
    details.EngineCapacity &&
      list.push({
        label: `${isElectric ? "pojemność akumulatora" : "pojemność"}: ${details.Capacity || details.EngineCapacity} ${
          isElectric ? "kWh" : "cm3"
        }`,
        url: null
      });

    if (isElectric && details?.VehicleRange) {
      list.push({
        label: `zasięg: ${details.VehicleRange} km`,
        url: null
      });
    }

    if (details?.Wltp) {
      list.push({
        label: `zużycie prądu (wltp): ${details.Wltp}`,
        url: null
      });
    }

    list.push({
      label: `moc (KM): ${details.HP || details.PowerHP}`,
      url: null
    });
    list.push({
      label: `moc (kW): ${details.KW || details.PowerKW}`,
      url: null
    });
    list.push({
      label: `liczba drzwi: ${details.DoorsCount || details.DoorsNumber}`,
      url: null
    });
    list.push({
      label: `liczba siedzeń: ${details.SeatsCount || details.SeatsNumber}`,
      url: null
    });
    if (details.BodyType) {
      list.push({
        label: `typ nadwozia: ${details.BodyType}`,
        url: details.BodyType
      });
    }
    list.push({
      label: `kolor: ${details.Color.toLowerCase()}`,
      url: null
    });
    if (details.ColorInterior) {
      list.push({
        label: `kolor tapicerki: ${details.ColorInterior.toLowerCase()}`,
        url: null
      });
    }
    if (details.FuelConsumptionTotal && !isElectric) {
      list.push({
        label: `spalanie w cyklu miejskim: ${details.FuelConsumptionTotal}`,
        url: null
      });
    }
    if (details.FuelConsumptionOutOfTownCycle && !isElectric) {
      list.push({
        label: `spalanie w cyklu pozamiejskim: ${details.FuelConsumptionOutOfTownCycle}`,
        url: null
      });
    }
    if (details.FuelConsumptionMixedCycle && !isElectric) {
      list.push({
        label: `spalanie w cyklu mieszanym: ${details.FuelConsumptionMixedCycle}`,
        url: null
      });
    }
    if (this.state.pageType === "afterLease") {
      list.push({
        label: `kategoria: ${"samochody poleasingowe"}`,
        url: "samochody-poleasingowe"
      });
    }
    if (this.state.pageType === "new") {
      list.push({
        label: `kategoria: ${"nowe samochody"}`,
        url: "nowe-samochody"
      });
    }
    if (this.state.pageType === "new") {
      list.push({
        label: `numer referencyjny: ${details.ReferenceNumber}`,
        url: null
      });
    }
    if (this.state.pageType === "new" && details.Co2Emission && !isElectric) {
      list.push({
        label: `emisja co2: ${details.Co2Emission}`,
        url: null
      });
    }
    this.setState({ technicalInformationList: list });
  }

  setParams(paramsValue) {
    this.setState({ paramsValue });
  }

  returnToPreviousPage() {
    if (this.props.history.length === 1) {
      this.props.history.push("/lista-pojazdow");
    } else {
      this.props.history.go(-1);
    }
  }

  scrollToCharacteristicList() {
    charListRef.current.scrollIntoView();
  }

  mapLabelColor(color) {
    let colorName = "";
    switch (color.toLowerCase()) {
      case "blue":
      default:
        colorName = "lochmaraBlueUI30";
        break;
      case "yellow":
        colorName = "selectiveYellow";
        break;
      case "green":
        colorName = "funGreenUI";
        break;
      case "orange":
        colorName = "clementineOrange";
        break;
      case "red":
        colorName = "brightRedUI";
        break;
    }
    return colorName;
  }

  setBackgroundImage(pic) {
    this.setState({ backgroundPhoto: pic });
  }

  setContactUrl(url) {
    this.setState({ contactUrl: url });
  }

  setRecommendedVehicleActive(index) {
    if (index >= 0 && index < this.state.recommendedCars.length)
      this.setState({
        currentlySelectedRecommendedVehicle: index
      });
  }

  changeDocumentBodyOverflow(hideOverflow) {
    let root = document.getElementById("root");
    if (root) {
      root.style.overflowY = hideOverflow ? "hidden" : "unset";
    }
  }

  handleClickCalc() {
    if (document.querySelector(".floating-wrapper")) {
      document.querySelector(".floating-wrapper").style.display = "block";
      this.changeDocumentBodyOverflow(true);
    }
    if (document.querySelector(".reservation-info-container")) {
      document.querySelector(".reservation-info-container").style.dispaly = "none";
    }

    if (document.querySelector(".calculator-container")) {
      document.querySelector(".calculator-container").style.display = "block";
    }
  }

  getCorrectEquipment(details) {
    if (details == null) {
      return [];
    } else {
      if (details.Equipment && details.Equipment.length) {
        return this.characteristicItemHandler(details.Equipment);
      } else if (details.EquipmentStandard && details.EquipmentStandard.length) {
        return this.characteristicItemHandler(details.EquipmentStandard);
      } else {
        return [];
      }
    }
  }

  handleCarouselOnChange() {
    imitationClickForCloseAllTooltips();
  }

  setLocalStorageHistory = (vehicleRequestData) => {
    const historyData = JSON.parse(localStorage.getItem("viewHistory")) || [];
    if (historyData && historyData.length < 15) {
      historyData.push({
        vehicle: `${vehicleRequestData.data.Make} ${vehicleRequestData.data.Model}`,
        link: window.location.pathname
      });
    } else if (historyData.length >= 15) {
      historyData.shift();
      historyData.push({
        vehicle: `${vehicleRequestData.data.Make} ${vehicleRequestData.data.Model}`,
        link: window.location.pathname
      });
    }
    localStorage.setItem("viewHistory", JSON.stringify(historyData));
  };

  setUrlDataFromCalc = (urlString) => {
    this.setState({ urlObject: urlString });
  };

  checkIfShownSlider = (isShowSlider) => {
    this.setState({ isShowSlider: isShowSlider });
  };

  handleContactModal = (data) => {
    this.setState({ isContactModalOpen: !this.state.isContactModalOpen });
    data && this.setUrlDataFromCalc(data);
  };

  handleCurrentCalcPrices = (purePrice, totalPrice) => {
    this.setState({
      calcCurrentPrices: {
        purePrice: purePrice,
        totalPrice: totalPrice
      }
    });
  };

  render() {
    let data = this.state;
    let details = this.state.details ? this.state.details[this.state.currentlySelectedVariant] : null;
    let chips = details && details.MarketingLabels ? JSON.parse(details.MarketingLabels) : null;
    const equipment = this.getCorrectEquipment(details);
    const equipmentAdditional =
      (details && details.EquipmentAdditional && this.characteristicItemHandler(details.EquipmentAdditional)) || [];
    const isWebpSupported = webpSupported();
    const isProgramMyElectric = details?.MyElectric;
    const isNewVehicle = this.state.pageType === "new";
    const isAfterLease = this.state.pageType === "afterLease";
    const showCalc =
      !this.state.isCalculatorLoading &&
      this.state.calculatorData &&
      ((this.state.pageType === "new" && this.state.calculatorData.AdditionalServiceItemsForNewCarRent) ||
        this.state.calculatorData.AdditionalServiceItems);
    const installmentValues = {
      leaseValue: (details && details.InstallmentLeasingPriceNetto) || "",
      rentValue: (details && details.InstallmentRentPriceNetto) || ""
    };
    const seoModelName=`${details?.Make.toLowerCase()}_${details?.Model.toLowerCase()}`
    const modelNameForHeaders=`${details?.Make} ${details?.Model} `
    const isSeoModel = !!seoModels[seoModelName];
    return (
      <>
        {details && !this.state.showLoader && this.state.calculatorData ? (
          <ToastContext.Consumer>
            {(toastState) => {
              return (
                <div className={`details-page-container ${isProgramMyElectric ? "white" : ""}`}>
                  <DetailsPageHelmet
                    pageType={this.state.pageType}
                    paramId={cutIdFromMathParams(this.props.match.params.id)}
                    details={details}
                  ></DetailsPageHelmet>
                  <MobileSearchContext.Consumer>
                    {({ isMobileSearchOpen }) => {
                      return (
                        isMobileSearchOpen && (
                          <MobileMainPageSearch
                            avalibleMakesAndModels={this.props.avalibleMakesAndModels}
                            avalibleBodyTypes={this.props.avalibleBodyTypes}
                          />
                        )
                      );
                    }}
                  </MobileSearchContext.Consumer>
                  <div className={`background without-opacity default ${isWebpSupported ? "webp" : "no-webp"}`} />
                  <div className="mleasing-main-container">
                    <Breadcrumbs model={details.Model} type={this.state.pageType} />
                  </div>
                  <div className="upper-container">
                    <div className="mleasing-main-container two-thirds-width witho">
                      <div className="content-wrapper">
                        <div className="left-wing mobile-padless">
                          <div className="basic-details-information-container">
                            <Media.DesktopOnly>
                              <DetailNavigationContainer
                                literals={this.state.literals}
                                returnToPreviousPage={this.returnToPreviousPage.bind(this)}
                                carId={details.AuctionId}
                                pageType={data.pageType}
                              />
                            </Media.DesktopOnly>
                            <span className="show-on-desktop direction-column">
                              <div className="basic-car-information-container title-container">
                                <Text
                                  as="h1"
                                  color={FontColor.DARK}
                                  fontSize={FontSize.SIZE_40}
                                  lineHeight={LineHeight.HEIGHT_48}
                                >
                                  {`${details.Make} ${details.Model}`}
                                </Text>
                              </div>
                              {this.state.pageType !== "new" && (
                                <div className="car-details-container">
                                  <div className="car-details-data">
                                    {details.Type && (
                                      <CarDetailsInformationTile
                                        icon={Car}
                                        text={replaceSpecialCharsets(details.Type)}
                                      />
                                    )}
                                    {details.FuelType && (
                                      <CarDetailsInformationTile icon={FuelPump} text={details.FuelType} />
                                    )}
                                    {details.PowerHP && (
                                      <CarDetailsInformationTile icon={Engine} text={`${details.PowerHP} KM`} />
                                    )}
                                    {details.GearBoxType && (
                                      <CarDetailsInformationTile icon={Gearbox} text={details.GearBoxType} />
                                    )}
                                    {details.PlateNumber && (
                                      <CarDetailsInformationTile icon={InfoRound} text={details.PlateNumber} />
                                    )}
                                  </div>
                                  <div className="car-details-tags">
                                    <Tag textColor={FontColor.LIGHT} backgroundColor="doveGrayUI">
                                      używany
                                    </Tag>
                                    {chips &&
                                      chips.map((label) => {
                                        return (
                                          <Tag key={uuid()} isBorder>
                                            {label.Text}
                                          </Tag>
                                        );
                                      })}
                                    {details.IsCertified && <Tag isBorder>certyfikowany</Tag>}
                                  </div>
                                </div>
                              )}

                              {this.state.pageType === "new" && (
                                <div className="car-details-container">
                                  <div className="car-details-data">
                                    {details.Type && (
                                      <CarDetailsInformationTile
                                        icon={Car}
                                        text={replaceSpecialCharsets(details.Type)}
                                      />
                                    )}
                                    {details.FuelType && (
                                      <CarDetailsInformationTile icon={FuelPump} text={details.FuelType} />
                                    )}
                                    {details.PowerHP && (
                                      <CarDetailsInformationTile icon={Engine} text={`${details.PowerHP} KM`} />
                                    )}
                                    {details.GearBoxType && (
                                      <CarDetailsInformationTile icon={Gearbox} text={details.GearBoxType} />
                                    )}
                                    {details.GearBoxType && (
                                      <CarDetailsInformationTile
                                        icon={Leaf}
                                        text={[details.Co2Emission, " CO", <sub>2</sub>]}
                                      />
                                    )}
                                  </div>
                                  <div className="car-details-tags">
                                    <Tag textColor={"zeusBlackUI"} backgroundColor={"confettiYellow"}>
                                      nowy
                                    </Tag>
                                    {chips &&
                                      chips.map((label) => {
                                        return (
                                          <Tag key={uuid()} isBorder>
                                            {label.Text}
                                          </Tag>
                                        );
                                      })}
                                    {details.IsCertified && <Tag isBorder>certyfikowany</Tag>}
                                  </div>
                                </div>
                              )}
                            </span>
                          </div>
                          <div>
                            <span className="basic-details-information-container show-on-mobile">
                              <div className="mobile-header">
                                <Text as="h1" styledAs="h2" color={FontColor.DARK}>
                                  {`${details.Make} ${details.Model}`}
                                </Text>
                                <Text as="h4" color={"doveGrayUI"}>
                                  {replaceSpecialCharsets(details.Type)}
                                </Text>
                              </div>
                            </span>
                          </div>
                          {(details.Gallery360Link || details.Photos) && (
                            <>
                              <VehicleGallery
                                photos={
                                  this.state.pageType === "new"
                                    ? details.Gallery360Link && details.PhotosVC && details.PhotosVC.length
                                      ? [...details.PhotosVC.filter((el, i) => i > 7)]
                                      : details.Photos
                                    : details.Gallery360Link
                                    ? details.Photos?.filter((el, i) => i > 7)
                                    : details.Photos
                                }
                                isWebP={details.IsWebP}
                                returnFullsizeCarImage={(pic) => this.setBackgroundImage(pic)}
                                virtualGallerySRC={details.Gallery360Link}
                                checkIfShownSlider={this.checkIfShownSlider}
                                carDetails={this.state.details[0]}
                                pageType = {this.state.pageType}
                                literals={this.state.literals}
                                urlToContactSection={this.state.urlToContactSection} 
                                handleContactModal={this.handleContactModal}
                              >
                                {!details.Reservation && details.SpecialPromotions && (
                                  <SpecialPromotions promotions={details.SpecialPromotions}></SpecialPromotions>
                                )}
                                <div
                                  className={`top-buttons ${
                                    this.state.isShowSlider ? "top-buttons--hide-left-border-radius" : ""
                                  }`}
                                >
                                  <ButtonCopyLink />
                                  <FavouriteButton carData={this.state.favouriteCarData} moduleType="DETAIL" />
                                </div>
                              </VehicleGallery>
                            </>
                          )}
                          <span className="basic-details-information-container show-on-mobile">
                            <Stack direction={Direction.COLUMN} spacing={8}>
                              {this.state.pageType !== "new" && (
                                <div className="notification-container">
                                  <Tag key={uuid()} backgroundColor="doveGrayUI">
                                    używany
                                  </Tag>
                                  {chips &&
                                    chips.map((label) => {
                                      return (
                                        <Tag key={uuid()} isBorder>
                                          {label.Text}
                                        </Tag>
                                      );
                                    })}
                                  {details.IsCertified && <Tag isBorder>certyfikowany</Tag>}
                                </div>
                              )}
                              {this.state.pageType === "new" && (
                                <Stack direction="row" spacing={8}>
                                  <Tag key={uuid()} backgroundColor="yellow30" textColor="zeusBlackUI">
                                    nowy
                                  </Tag>
                                  {chips &&
                                    chips.map((label) => {
                                      return (
                                        <Tag key={uuid()} isBorder>
                                          {label.Text}
                                        </Tag>
                                      );
                                    })}
                                  {details.IsCertified && <Tag isBorder>certyfikowany</Tag>}
                                </Stack>
                              )}
                              {this.state.pageType !== "new" && (
                                <div className="car-details-container">
                                  {details.Type && (
                                    <Media.DesktopOnly>
                                      <CarDetailsInformationTile
                                        icon={Car}
                                        text={replaceSpecialCharsets(details.Type)}
                                      />
                                    </Media.DesktopOnly>
                                  )}
                                  {details.FuelType && (
                                    <CarDetailsInformationTile icon={FuelPump} text={details.FuelType} />
                                  )}
                                  {details.PowerHP && (
                                    <CarDetailsInformationTile icon={Engine} text={`${details.PowerHP} KM`} />
                                  )}
                                  {details.GearBoxType && (
                                    <CarDetailsInformationTile
                                      icon={Gearbox}
                                      text={`${details.GearBoxType === "automatyczna" ? "automat" : "manual"}`}
                                    />
                                  )}
                                  {details.Co2Emission && (
                                    <CarDetailsInformationTile
                                      icon={Leaf}
                                      text={[details.Co2Emission, " CO", <sub>2</sub>]}
                                    />
                                  )}
                                  {details.PlateNumber && (
                                    <CarDetailsInformationTile icon={InfoRound} text={details.PlateNumber} />
                                  )}
                                </div>
                              )}

                              {this.state.pageType === "new" && (
                                <div className="car-details-container">
                                  {details.Type && (
                                    <Media.DesktopOnly>
                                      <CarDetailsInformationTile
                                        icon={Car}
                                        text={replaceSpecialCharsets(details.Type)}
                                      />
                                    </Media.DesktopOnly>
                                  )}
                                  {details.FuelType && (
                                    <CarDetailsInformationTile icon={FuelPump} text={details.FuelType} />
                                  )}
                                  {details.PowerHP && (
                                    <CarDetailsInformationTile icon={Engine} text={`${details.PowerHP} KM`} />
                                  )}
                                  {details.GearBoxType && (
                                    <CarDetailsInformationTile
                                      icon={Gearbox}
                                      text={`${details.GearBoxType === "automatyczna" ? "automat" : "manual"}`}
                                    />
                                  )}
                                  {details.Co2Emission && (
                                    <CarDetailsInformationTile
                                      icon={Leaf}
                                      text={[details.Co2Emission, " CO", <sub>2</sub>]}
                                    />
                                  )}
                                </div>
                              )}
                            </Stack>
                          </span>
                            {!!this.state.details[0].SpecialPromotions.length && (
                            <div className="promo-banner-container--mobile">
                              <PromoBanner isAfterLease={isAfterLease} literals={this.state.literals} urlToContactSection={this.state.urlToContactSection} handleContactModal={this.handleContactModal}/>
                            </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mleasing-main-container bg-transparent sticky one-third-width ${
                        this.state.pageType === "auction" ? "visible-in-struct" : ""
                      }`}
                    >
                      <div className="content-wrapper">
                        <div className="flex-end">
                          {showCalc && (
                            <Calculator
                              pageType={this.state.pageType}
                              calcData={this.state.calculatorData}
                              calcResult={this.state.calculatorResult}
                              calcRaw={this.state.calculatorRaw}
                              contactUrl={(url) => {
                                this.setContactUrl(url);
                              }}
                              literals={this.state.literals}
                              handler={(e) => this.getPrice(e)}
                              isCompany={this.state.isCompany}
                              isProgramMyElectric={isProgramMyElectric}
                              reservation={details.Reservation}
                              setShowCalc={(val) => this.setShowCalc()}
                              setIsCompany={(e) => this.setIsCompany(e)}
                              setParams={(val) => this.setParams(val)}
                              setCalculatorTabSelected={this.setCalculatorTabSelected}
                              handleContactModal={this.handleContactModal}
                              installmentValues={installmentValues}
                              handleCurrentCalcPrices={this.handleCurrentCalcPrices}
                              sendDataToParent={this.updateChildData}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <DetailsNavigation
                      isNewVehicle={isNewVehicle}
                      isCertified={details.IsCertified}
                      additionalEquipment={equipmentAdditional.length}
                      isSeoModel={isSeoModel}
                      modelNameForHeaders={modelNameForHeaders}
                    />
                    {isProgramMyElectric &&
                      this.state.literals &&
                      (this.state.literals?.silverbox_title || this.state.literals?.silverbox_text) && (
                        <div className="mleasing-main-container without-max-width my-electric-section">
                          <div className="content-wrapper">
                            <div className="wrapper">
                              {this.state.literals?.silverbox_title && (
                                <Text as="h2" marginBottom={24} className="my-electric-section__title">
                                  {this.state.literals.silverbox_title}
                                </Text>
                              )}
                              {this.state.literals?.silverbox_text && (
                                <Text as="p" marginBottom={32}>
                                  {this.state.literals.silverbox_text}
                                </Text>
                              )}
                              {this.state.literals?.silverbox_btn_link && this.state.literals?.silverbox_btn_title && (
                                <Link to={this.state.literals.silverbox_btn_link} target="_blank">
                                  <Button>{this.state.literals.silverbox_btn_title}</Button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="mleasing-main-container bg-white without-max-width">
                      <div className="content-wrapper">
                        <div className="left-wing">
                          {details.Reservation && (
                            <div className="show-on-mobile calc-result-container">
                              <div
                                className={`reservation-info-container ${this.props.setDarkMode ? "dark" : "white"}`}
                              >
                                <ReservedLabel text={"zarezerwowany"} iconType={Lock} color={"zeusBlackUI"} textAs="p"/>
                                <Text as="p" styledAs="h2" marginTop={16} marginBottom={24}>
                                  szukasz podobnego auta?
                                </Text>
                                <div className="tiles-wrapper">
                                  <div>
                                    <Icon iconComponent={PhoneHandle} primarycolor={IconColor.BLUE} />
                                    <Text as="p" styledAs="h3" marginTop={8} fontWeight={FontWeight.WEIGHT_600}>
                                      zadzwoń
                                    </Text>
                                    <Text as="p" marginBottom={8}>
                                      {this.state.literals && this.state.literals.callusbox_time}
                                    </Text>
                                    <a
                                      href={
                                        this.state.literals &&
                                        `tel:${this.state.literals.callusbox_tel.replace(/ /g, "")}`
                                      }
                                    >
                                      {this.state.literals && this.state.literals.callusbox_tel}
                                    </a>
                                  </div>
                                  <div>
                                    <Icon iconComponent={Envelope} primarycolor={IconColor.BLUE} />
                                    <Text as="p" styledAs="h3" marginTop={8} fontWeight={FontWeight.WEIGHT_600}>
                                      napisz
                                    </Text>
                                    <Text as="p" marginBottom={8}>
                                      odpowiedź w 24h
                                    </Text>
                                    <Link to={`/formularz-kontaktowy?${this.state.contactUrl}`}>skontaktuj się</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {details.Reservation && !this.state.showCalc && (
                            <div className="show-on-mobile calc-result-container">
                              <div className={`launch-preview-box ${this.props.setDarkMode ? "dark" : "white"}`}>
                                <Text as="p" styledAs="h2">
                                  finansowanie
                                </Text>
                                <Text as="p" marginBottom={8}>
                                  zobacz ile kosztowałby Cię ten samochód
                                </Text>
                                <Button
                                  buttonType={ButtonType.TERTIARY}
                                  onClick={() => {
                                    this.setShowCalc();
                                  }}
                                >
                                  oblicz ratę miesieczną
                                </Button>
                              </div>
                            </div>
                          )}
                          {!details.Reservation ||
                            (details.Reservation && this.state.showCalc && (
                              <div className="show-on-mobile calc-result-container">
                                <Stack direction={"column"} spacing={24}>
                                  <Stack direction={"row"} alignX={Alignment.SPACE_BETWEEN}>
                                    <div>
                                      <Text
                                        fontSize={FontSize.SIZE_12}
                                        color={"doveGrayUI"}
                                        as={"p"}
                                        fontWeight={FontWeight.WEIGHT_600}
                                        lineHeight={LineHeight.HEIGHT_16}
                                      >
                                        rata miesięczna netto
                                      </Text>
                                    </div>
                                    <div className="amount-container">
                                      {this.state.latestCalcConfig &&
                                        this.state.latestCalcConfig.InstallmentPriceNetto && (
                                          <Text as="p" styledAs="h2">
                                            <Amount
                                              isFractionPartShown={false}
                                              currencyDisplay="symbol"
                                              currency="PLN"
                                              isFractionPartBolded={true}
                                              isFractionPartResized={false}
                                              // styledAsTextTagName={"h2"}
                                              value={this.state.latestCalcConfig.InstallmentPriceNetto}
                                            />
                                          </Text>
                                        )}
                                      {this.state.calculatorResult && this.state.calculatorResult.InstallmentPrice && (
                                        <Text as="p" styledAs="h2">
                                          <Amount
                                            isFractionPartShown={false}
                                            currencyDisplay="symbol"
                                            currency="PLN"
                                            isFractionPartBolded={true}
                                            isFractionPartResized={false}
                                            // styledAsTextTagName={"h2"}
                                            value={this.state.calculatorResult.InstallmentPrice}
                                          />
                                        </Text>
                                      )}
                                    </div>
                                  </Stack>
                                  <Button
                                    buttonType="secondary"
                                    onClick={() => {
                                      this.handleClickCalc();
                                    }}
                                  >
                                    {this.state.pageType === "auction" ? "licytuj" : "kalkulator finansowania"}
                                  </Button>
                                  {this.state.contactUrl && (
                                    <Link to={`/formularz-kontaktowy?${this.state.contactUrl}`}>
                                      <Button buttonType={ButtonType.PRIMARY} isFullWidth={true}>
                                        zapytaj o ofertę
                                      </Button>
                                    </Link>
                                  )}
                                </Stack>
                              </div>
                            ))}
                          <CharacteristicFeaturesList
                            title={`${isSeoModel?modelNameForHeaders:''}${this.state.literals && this.state.literals.prod_data}`}
                            list={data.technicalInformationList}
                            isTechnicalDataList
                            refEl={charListRef}
                            id="technicalInformation"
                          />
                          <div className={"availability-information-container"}>
                            {details.Location && (
                              <CharacteristicFeaturesList
                                disableShowMore={true}
                                list={
                                  !details.AvailableFrom
                                    ? [{ label: `miasto: ${details.Location}` }]
                                    : [
                                        { label: `miasto: ${details.Location}` },
                                        {
                                          label: `${
                                            details.AvailableFrom ? `dostępność: ${details.AvailableFrom}` : null
                                          }`
                                        }
                                      ]
                                }
                                title={
                                  this.state.literals &&
                                  (!details.AvailableFrom
                                    ? this.state.literals.prod_geo_hdr
                                    : `${this.state.literals.prod_geo_hdr} i dostępność`)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mleasing-main-container bg-gray without-max-width">
                      <div className="content-wrapper">
                        <div className="left-wing">
                          {!!equipment.length && (
                            <EquipmentList
                              title={`${isSeoModel?modelNameForHeaders:''}${
                                this.state.pageType === "new"
                                  ? this.state.literals && this.state.literals.prod_new_eqpmnt_hdr
                                  : this.state.literals && this.state.literals.prod_nused_eqpmnt_hdr
                              }`}
                              list={equipment}
                              id="standardEquipment"
                            />
                          )}
                          {this.state.pageType === "new" && !!equipmentAdditional.length && (
                            <EquipmentList
                              title={`${isSeoModel?modelNameForHeaders:''}${this.state.literals && this.state.literals.prod_new_addeqmnt_hdr}`}
                              list={equipmentAdditional}
                              id="additionalEquipment"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="certifiedDetailsContainer" className="mleasing-main-container bg-white without-max-width">
                      <div className="content-wrapper">
                        <div className="left-wing">
                          {details.IsCertified && (
                            <DetailsCertificateWidget
                              widgetUrl={details.Certificate.WidgetUrl}
                              onePageUrl={details.Certificate.OnePageUrl}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.pageType !== "auction" && this.state.calculatorTabSelected !== 3 && (
                      <div className="mleasing-main-container bg-gray without-max-width" id="additionalServices">
                        <div className="content-wrapper">
                          <div className="left-wing">
                            <AdditionalPackagesSection
                              calcData={this.state.calculatorData}
                              literals={this.state.literals}
                              offerType={this.state.pageType}
                              calculatorTabSelected={this.state.calculatorTabSelected}
                              isSeoModel={isSeoModel}
                              modelNameForHeaders={modelNameForHeaders}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lower-container">
                    <div className="mleasing-main-container bg-white without-max-width">
                      {isSeoModel && (
                        <div className="seo-section-container">
                          <div id="seo-section" className="seo-section content-wrapper" dangerouslySetInnerHTML={{ __html: seoModels[seoModelName]}}></div>
                        </div>
                      )}
                      <DetailWhyUs />
                      <AwardBanner/>
                      <CarsCarousel
                        carsType={"recommended"}
                        carsCount={this.state.recommendedCars.length}
                        carsList={this.state.recommendedCars}
                        changeHandler={() => this.handleCarouselOnChange()}
                        buttonText={data.pageType === "afterLease" ? "wszystkie auta używane" : "wszystkie auta nowe"}
                        buttonLink={data.pageType === "afterLease" ? "/samochody-poleasingowe" : "/nowe-samochody"}
                        sectionTitle={"samochody, które mogą Cię zainteresować"}
                        sectionDescription={"dostępne od ręki z dostawą pod dom"}
                        theme="white"
                      />
                    </div>
                  </div>
                  {this.state.isContactModalOpen && this.state.urlObject && chips && (
                    <ContactModal
                      handleContactModal={this.handleContactModal}
                      urlObject={this.state.urlObject}
                      calcPrice={this.state.calculatorRaw}
                      pageType={data.pageType}
                      chips={chips}
                      calcCurrentPrices={this.state.calcCurrentPrices}
                    />
                  )}
                </div>
              );
            }}
          </ToastContext.Consumer>
        ) : (
          <LoadingSpinner />
        )}
      </>
    );
  }
}

export default withRouter(DetailsPage);
