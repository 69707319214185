import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

class ListPageHelmet extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  modifyMetaString(string) {
    const seg = string && string.split("-")
    return seg && seg.map((word)=>this.capitalizeFirstLetter(word)).join(" ")
  }

  extractParameters = (path) => {
    const segments = path.split("/").filter(Boolean);
    let params = {
      carState: null,
      fuelType: null,
      make: null,
      model: null,
      bodyType: null
    };

    const carStateMap = {
      "samochody-poleasingowe": "Samochód poleasingowy",
      "nowe-samochody": "Nowy samochód",
      "samochody-elektryczne": "Samochód elektryczny"
    };

    segments.forEach((segment) => {
      if (carStateMap[segment]) {
        if (segment === "samochody-elektryczne") {
          params.fuelType = carStateMap[segment];
        } else {
          params.carState = carStateMap[segment];
        }
      } else if (segment.startsWith("marki")) {
        params.make = segment.split("/")[1];
      } else {
        const makeAndModel = segment.split("-");
        if (makeAndModel.length > 1) {
          if (makeAndModel[0] === "land" || makeAndModel[0] === "mercedes"|| makeAndModel[0] === "alfa") {
            params.make = makeAndModel[0] + "-" + makeAndModel[1]
            params.model = makeAndModel.slice(2).join("-");
          } else {
            params.make = makeAndModel[0];
            params.model = makeAndModel.slice(1).join("-");
          }
        } else {
          params.make = makeAndModel[0];
        }
      }
    });

    return params;
  };

  render() {
    const path = this.props.location.pathname;
    const params = this.extractParameters(path);
    // let filteredMultipleVariantsInfo = this.props.multipleVariantsInfo && this.props.multipleVariantsInfo.filter(
    //   (car) =>
    //     this.props.currentPathname.toLowerCase().includes(car.Make.toLowerCase()) &&
    //     this.props.currentPathname.toLowerCase().includes(car.Model.toLowerCase())
    // ) || [];
    return (
      <>
        {this.props.location.pathname.includes("/sedan") && (
          <Helmet>
            <title>sedan - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="sedan - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/hatchback") && (
          <Helmet>
            <title>hatchback - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="hatchback - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/kombi") && (
          <Helmet>
            <title>kombi - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="kombi - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/suv") && (
          <Helmet>
            <title>SUV - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="SUV - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/bus") && (
          <Helmet>
            <title>BUS - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="BUS - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/van") && (
          <Helmet>
            <title>VAN - samochody osobowe | mAuto.pl</title>
            <meta name="description" content="VAN - samochody osobowe | mAuto.pl"></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/marki/") && (
          <Helmet>
            <title>
              {this.props.location.pathname.substring(
                this.props.location.pathname.indexOf("/marki/") + "/marki/".length
              )}{" "}
              - samochody osobowe | mAuto.pl
            </title>
            <meta
              name="description"
              content={
                this.props.location.pathname.substring(
                  this.props.location.pathname.indexOf("/marki/") + "/marki/".length
                ) + " - samochody osobowe | mAuto.pl"
              }
            ></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/nowe-samochody") && (
          <Helmet>
            <title>Nowe samochody | mAuto.pl</title>
            <link rel="canonical" href={window.location.href}/>
            <meta
              name="description"
              content="Szukasz nowego samochodu? Sprawdź ofertę mAuto i znajdź swój ideał. Duży wybór, najlepsze modele, atrakcyjne oferty leasingowe."
            ></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/samochody-poleasingowe") && (
          <Helmet>
            <title>Samochody poleasingowe | mAuto.pl</title>
            <link rel="canonical" href={window.location.href}/>
            <meta
              name="description"
              content="Sprawdź ofertę samochodów poleasingowych w atrakcyjnych cenach. Doskonała oferta, świetne ceny, najlepsze modele. Wybierz swój ideał z mAuto."
            ></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/samochody-elektryczne") && (
          <Helmet>
            <title>Samochody elektryczne dostępne w mAuto</title>
            <link rel="canonical" href={window.location.href}/>
            <meta
              name="description"
              content="Sprawdź samochody elektryczne z oferty wirtualnego salonu mAuto. Wybierz jeden z atrakcyjnych modeli i dostosuj finansowanie do potrzeb swojej firmy."
            ></meta>{" "}
          </Helmet>
        )}
        {/* Section for path /wzorowe-samochody is removed from app. 
            To maintain old links we replace that section with copy of section for /samochody-poleasingowe */}
        {this.props.location.pathname.includes("/wzorowe-samochody") && (
          <Helmet>
            <title>Samochody poleasingowe | mAuto.pl</title>
            <meta
              name="description"
              content="Sprawdź ofertę samochodów poleasingowych w atrakcyjnych cenach. Doskonała oferta, świetne ceny, najlepsze modele. Wybierz swój ideał z mAuto."
            ></meta>{" "}
          </Helmet>
        )}
        {this.props.location.pathname.includes("/aukcje-samochodowe") && (
          <Helmet>
            <title>Aukcje samochodów w mAuto – sprawdzone pojazdy, dobre ceny</title>
            <meta
              name="description"
              content="Skorzystaj z wyjątkowych okazji i licytuj samochody poleasingowe dostępne na aukcjach mAuto. Wybierz doskonałe auta w niskich cenach."
            ></meta>
          </Helmet>
        )}
        {this.props.location.pathname.includes("/lista-pojazdow") && (
          <Helmet>
            <title>Lista ofert pojazdów | mAuto.pl</title>
            <meta
              name="description"
              content="Aukcje samochodowe, auta poleasingowe i nowe – w ofercie mAuto każdy znajdzie pojazd idealny dla siebie. Sprawdź naszą stronę!"
            ></meta>
          </Helmet>
        )}
        {params.make && !path.includes("/lista-pojazdow") && path !== "/nowe-samochody" && path !== "/samochody-poleasingowe" && (
          <Helmet>
            <title>
              {params.carState ? params.carState : params.fuelType ? params.fuelType : ""} {this.modifyMetaString(params.make)} {this.modifyMetaString(params.model ? params.model : "")} | mAuto.pl
            </title>
            <link rel="canonical" href={window.location.href}/>
            <meta
              name="description"
              content={`${params.carState ? params.carState : params.fuelType ? params.fuelType : ""} ${params.make} ${
                params.model ? params.model : ""
              }| mAuto.pl`}
            ></meta>{" "}
          </Helmet>
        )}
        {this.props.isMultipleCarLink && (
          <Helmet>
            <title>
              {this.props.multipleVariantsInfo &&
                this.props.multipleVariantsInfo[0] &&
                `${this.capitalizeFirstLetter(this.props.multipleVariantsInfo[0].Make)} ${
                  this.props.multipleVariantsInfo[0].Model
                } - `}{" "}
              Lista ofert pojazdów | mAuto.pl
            </title>
            <meta
              name="description"
              content="Aukcje samochodowe, auta poleasingowe i nowe – w ofercie mAuto każdy znajdzie pojazd idealny dla siebie. Sprawdź naszą stronę!"
            ></meta>
          </Helmet>
        )}
      </>
    );
  }
}

export default withRouter(ListPageHelmet);
