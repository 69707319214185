import React, { useEffect } from "react";
import "./index.scss";
import ContactFormForm from "../../modules/ContactFormSection";
import { Text, Media } from "@mbank-design/design-system/components";
import { FontColor } from "@mbank-design/design-system/enums";
import { FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";

import backgroundImageWeb from "./img/tesla_web.jpg";
import backgroundImageMobile from "./img/tesla_mobile.jpg";
import backgroundImageWebWebP from "./img/tesla_web.webp";
import backgroundImageMobileWebP from "./img/tesla_mobile.webp";
import { clearCampaignData, setCampaignData } from "../../common/campaign-data-setter";
import { Helmet } from "react-helmet";

const LandingPageTeslaY = (props) => {
  const isWebpSupported = webpSupported();

  useEffect(()=>{
    clearCampaignData();
    setCampaignData()
  },[])
  
  const downloadSiteRules = () => {
    props.downloadSiteRules().then((res) => {
      props.clickAnchorWithRegulations(res);
    });
  };


  return (
    <div className="landingPageTeslaY">
      <div className="landingPageTeslaY__top">
        <div className="landingPageTeslaY__left">
          <Helmet>
          <link rel="canonical" href={window.location.href}/>
          </Helmet>
          <Media.DesktopOnly>
            <img
              src={isWebpSupported ? backgroundImageWebWebP : backgroundImageWeb}
              alt="tesla car"
              className="landingPageTeslaY__background-image"
            />
          </Media.DesktopOnly>
          <Media.MobileOnly>
            <img
              src={isWebpSupported ? backgroundImageMobileWebP : backgroundImageMobile}
              alt="tesla car"
              className="landingPageTeslaY__background-image"
            />
          </Media.MobileOnly>
          <Media.DesktopOnly>
            <div className="landingPageTeslaY__bottom-text">
            <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
              >
                Rata 2099 zł dotyczy finansowania modelu Tesla Y Standard przy założeniu, że: opłata wstępna 0 zł netto,
                okres finansowania 36 miesięcy, całkowity limit przebiegu 45 tys. km. Rata obejmuje pakiet serwisowy
                Basic, ubezpieczenie komunikacyjne AC, OC NNW. Koszt finansowania ubezpieczenia – 2,5% w skali roku –
                rozliczany dodatkową fakturą. Rata uwzględnia dopłatę w ramach Programu Narodowego Funduszu Ochrony
                Środowiska i Gospodarki Wodnej „Mój elektryk”. Wysokość dopłaty do leasingu uzależniona jest od
                zadeklarowanego przebiegu oraz tego, czy firma jest podatnikiem VAT.
              </Text>
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
              >
                mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z
                o.o. Informacja jest informacją handlową i nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego.
                Ma charakter jedynie poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa wymaga formy pisemnej
                pod rygorem nieważności i może być zawarta nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o.
                pozytywnej decyzji o finansowaniu.
              </Text>
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
                marginBottom={16}
              >
                Prezentacja pojazdów na stronie nie gwarantuje ich dostępności. Proces weryfikacji dostępności odbywa
                się po wysłaniu formularza kontaktowego. Informujemy, iż mLeasing Sp. z o.o. z siedzibą w Warszawie przy
                ul. Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem Pani(a) danych osobowych.
                Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez mLeasing Sp. z o.o. w celach
                umożliwienia korzystania Pani(u) z Serwisu mAuto na warunkach zgodnych z Regulaminem, w tym w celach
                przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub numer telefonu, w celach
                marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach statystycznych i
                analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą przysługuje prawo żądania
                od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
                prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn związanych ze szczególną
                sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby marketingu, w tym
                profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.
                Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie danych, w każdym
                momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
                cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez mLeasing Danych
                osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych
                Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów. Więcej informacji
                o zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem{" "}
                <a href={"mailto:daneosobowe@mleasing.pl"}>daneosobowe@mleasing.pl</a> lub znajdziesz w{" "}
                <a onClick={downloadSiteRules} target="_blank">
                  Regulaminie Serwisu.
                </a>
              </Text>
            </div>
          </Media.DesktopOnly>
        </div>

        <div className="landingPageTeslaY__contact-form-section">
          <ContactFormForm isSpecialLandingPage lpTeslaY />
        </div>
        <Media.MobileOnly>
          <div className="landingPageTeslaY__bottom-text">
          <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
            >
              Rata 2099 zł dotyczy finansowania modelu Tesla Y Standard przy założeniu, że: opłata wstępna 0 zł netto,
              okres finansowania 36 miesięcy, całkowity limit przebiegu 45 tys. km. Rata obejmuje pakiet serwisowy
              Basic, ubezpieczenie komunikacyjne AC, OC NNW. Koszt finansowania ubezpieczenia – 2,5% w skali roku –
              rozliczany dodatkową fakturą. Rata uwzględnia dopłatę w ramach Programu Narodowego Funduszu Ochrony
              Środowiska i Gospodarki Wodnej „Mój elektryk”. Wysokość dopłaty do leasingu uzależniona jest od
              zadeklarowanego przebiegu oraz tego, czy firma jest podatnikiem VAT.
            </Text>
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
            >
              mAuto jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z o.o.
              Informacja jest informacją handlową i nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego. Ma
              charakter jedynie poglądowy i pomocniczy w przygotowaniu właściwej umowy. Umowa wymaga formy pisemnej pod
              rygorem nieważności i może być zawarta nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o. pozytywnej
              decyzji o finansowaniu.
            </Text>
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
              marginBottom={16}
            >
              Prezentacja pojazdów na stronie nie gwarantuje ich dostępności. Proces weryfikacji dostępności odbywa się
              po wysłaniu formularza kontaktowego. Informujemy, iż mLeasing Sp. z o.o. z siedzibą w Warszawie przy ul.
              Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem Pani(a) danych osobowych.
              Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez mLeasing Sp. z o.o. w celach
              umożliwienia korzystania Pani(u) z Serwisu mAuto na warunkach zgodnych z Regulaminem, w tym w celach
              przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub numer telefonu, w celach
              marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach statystycznych i
              analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą przysługuje prawo żądania
              od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
              prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn związanych ze szczególną
              sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby marketingu, w tym
              profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.
              Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie danych, w każdym
              momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
              cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez mLeasing Danych
              osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych
              Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów. Więcej informacji o
              zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem{" "}
              <a href={"mailto:daneosobowe@mleasing.pl"}>daneosobowe@mleasing.pl</a> lub znajdziesz w{" "}
              <a onClick={downloadSiteRules} target="_blank">
                Regulaminie Serwisu.
              </a>
            </Text>
          </div>
        </Media.MobileOnly>
      </div>
    </div>
  );
};

export default LandingPageTeslaY;
